@font-face {
  font-family: 'Formular';
  src: local('Formular Regular'), local('Formular-Regular'),
      url('./assets/fonts/Formular.woff2') format('woff2'),
      url('./assets/fonts/Formular.woff') format('woff'),
      url('./assets/fonts/Formular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html{
  height:100%;
  font-size: 16px;
  box-sizing: border-box;
}

body {
  height:100%;
  margin: 0;
  font-family: 'Formular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6{
  font-weight: 400;
}
