.abra-page {
  display: flex;
  height: 100%;
}
.abra-page.sign-in-page .page-content,
.abra-page.user-page .page-content,
.abra-page.invite-user-page .page-content,
.abra-page.change-password-page .page-content,
.abra-page.accept-terms-page .page-content {
  flex: 1;
}
.abra-page .page-filler {
  background-color: #001F40;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.abra-page .page-main {
  width: 60%;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.abra-page .page-content {
  padding: 20px;
  padding-top: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.abra-page .page-content.internal-content {
  padding-top: 100px;
  padding-left: 70px;
}
.abra-page .page-content .language-selector-container {
  position: absolute;
  top: 0;
  right: 16px;
}
.abra-page .page-content .content-card {
  width: 440px;
}
.abra-page .page-content .content-card.wide-card {
  width: 470px;
}
.abra-page.accept-terms-page .page-content .accept-message {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.55rem;
}
.abra-page.accept-terms-page .page-content .accept-message .accept-message-text {
  margin-top: 50px;
  white-space: pre-line;
}
.abra-page.accept-terms-page .page-content .terms-link {
  margin-top: 45px;
  text-align: center;
  font-size: 0.9rem;
}
.abra-page.accept-terms-page .page-content .terms-link a {
  color: #FFA060;
  text-decoration: none;
}
.abra-page.accept-terms-page .page-content .terms-link a:hover {
  color: #ed9154;
}
.abra-page.accept-terms-page .page-content .accept-button-container {
  display: flex;
  justify-content: center;
}
.abra-page.accept-terms-page .page-content .accept-button-container button {
  width: 80%;
}
.abra-page.accept-terms-page .page-content .read-more-container {
  margin-top: 30px;
  text-align: center;
}
.abra-page.accept-terms-page .page-content .read-more-container .read-more-link {
  display: inline-flex;
  align-items: center;
  color: #001F40;
  text-decoration: none;
}
.abra-page.accept-terms-page .page-content .read-more-container .arrow-right {
  margin-right: 20px;
}
@media (max-width: 991px) {
  .abra-page .page-filler {
    background-color: #001F40;
    width: 30%;
  }
  .abra-page .page-filler .front-image {
    width: 150px;
    height: 150px;
  }
  .abra-page .page-main {
    width: 70%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .abra-page {
    flex-direction: column;
  }
  .abra-page .page-filler {
    width: 100%;
    height: 50px;
  }
  .abra-page .page-filler .front-image {
    width: 40px;
    height: 40px;
  }
  .abra-page .page-main {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .abra-page .page-main .page-content {
    padding-top: 70px;
  }
  .abra-page .page-main .page-content .content-card {
    width: 80%;
  }
}
