.user-info .info-item-list {
  margin-top: 45px;
}
.user-info .info-item-list .info-item {
  display: flex;
  margin-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E5E9EC;
}
.user-info .info-item-list .info-item .info-item-label,
.user-info .info-item-list .info-item .info-item-value {
  width: 50%;
}
.user-info .info-item-list .info-item .info-item-label {
  color: #808F9F;
}
.user-info .info-item-list .info-item .info-item-value {
  color: #001F40;
}
.user-info .cg-psw-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.user-info .sign-out-container {
  margin-top: 35px;
  padding: 30px 0;
  border-top: 1px solid #E5E9EC;
  border-bottom: 1px solid #E5E9EC;
}
.user-info .sign-out-container .sign-out-btn {
  background: none;
  border: none;
  color: #f5786f;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.user-info .sign-out-container .sign-out-btn:hover {
  color: #e46a61;
}
.user-info .sign-out-container .sign-out-btn:hover svg {
  fill: #e46a61;
}
.user-info .sign-out-container .sign-out-btn:hover svg path {
  fill: #e46a61;
}
.user-info .sign-out-container .sign-out-btn .sign-out-img {
  margin-right: 12px;
}
