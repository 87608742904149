.navbar {
  background-color: #001F40;
  width: 280px;
  padding: 50px 35px;
  display: flex;
  flex-direction: column;
}
.navbar .navbar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.navbar .navbar-item .navbar-item-label {
  color: #808F9F;
  font-size: 15px;
  text-decoration: none;
}
.navbar .navbar-avatar-container {
  margin-top: 90px;
  display: flex;
  justify-content: center;
}
.navbar .navbar-avatar-container .navbar-avatar {
  width: 76px;
  height: 76px;
  border-radius: 5px;
}
.navbar .navbar-list {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.navbar .navbar-list .navbar-item {
  margin-bottom: 30px;
}
.navbar .navbar-list .navbar-item:hover .navbar-icon path,
.navbar .navbar-list .navbar-item.active .navbar-icon path {
  stroke: #ffffff;
}
.navbar .navbar-list .navbar-item:hover .navbar-item-label,
.navbar .navbar-list .navbar-item.active .navbar-item-label {
  color: white;
}
.navbar .navbar-list .navbar-item .navbar-icon {
  margin-right: 30px;
}
.navbar .navbar-list .navbar-item .navbar-icon path {
  stroke: #808F9F;
}
.navbar .navbar-user-info {
  padding-left: 5px;
  margin-top: auto;
}
.navbar .navbar-user-info .user-name-icon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
  border-radius: 5px;
  background-color: #DDF0FF;
  display: flex;
  justify-content: center;
  align-items: center;
}
