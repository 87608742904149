.form-title {
  text-align: center;
}
.back-link {
  position: absolute;
  top: 26px;
  left: -60px;
}
.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  color: #001F40;
  text-decoration: none;
  background-color: #FFA060;
  border: none;
  border-radius: 7px;
  margin-top: 25px;
  font-size: 1rem;
  cursor: pointer;
}
.submit-btn.btn-disabled {
  opacity: 0.8;
}
.submit-btn:hover:not(.btn-disabled) {
  background-color: #ed9154;
}
.form-link {
  color: #808F9F;
  font-size: 0.9rem;
  text-decoration: none;
}
.form-link:hover {
  color: #001F40;
  text-decoration: underline;
}
.form-error {
  margin: 10px 0;
  color: red;
}
.sign-in-form .submit-btn {
  margin-top: 25px;
}
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #001F40;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
